export const exportDownloadCSV = (headers: Array<string>, data: Array<Record<string, string>>, fileName: string) => {
  const csvContent = [headers.join(','), ...data.map((row) => Object.values(row).join(','))].join('\n');

  const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
