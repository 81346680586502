import {useContext, useEffect, useState} from 'react';

import {Button, CircularProgress, Modal, Typography} from '@mui/material';

import {Problem} from '../../types';
import {Form} from '../new-problem/NewProblem';
import {request, URLs} from '../../api';
import AppContext from '../../context/AppContext';

type UpdateProblemModalWrapperProps = {
  problemId: number;
  onSuccess: () => void;
  onClose: () => void;
};

function UpdateProblemModalWrapper({problemId, ...others}: UpdateProblemModalWrapperProps) {
  const {dispatchError} = useContext(AppContext);

  const [problemDetails, setProblemDetails] = useState<Problem | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [wasDataEdited, setWasDataEdited] = useState<boolean>(false);
  const [closeConfirmation, setCloseConfirmation] = useState<boolean>(false);

  useEffect(() => {
    request<{}, {}, Problem>(URLs.getProblemDetailed(problemId), {
      method: 'GET',
      successCallback: (response) => {
        setProblemDetails(response);
        setLoading(false);
      },
      errorCallback: () => {
        dispatchError({
          errorMessage: 'There was an error. Please try again later.',
          redirectURL: `/problem/${problemId}`,
        });
      },
    });
  }, [problemId]);

  return (
    <>
      <Modal open={true} onClose={() => (wasDataEdited ? setCloseConfirmation(true) : others.onClose())}>
        <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[80%] h-[90%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
          {!problemDetails ? (
            loading && (
              <div className="flex items-center justify-center h-screen">
                <CircularProgress size={74} color="success" />
              </div>
            )
          ) : (
            <Form
              title={problemDetails.title}
              languages={problemDetails.languages ?? []}
              difficulty={problemDetails.difficulty}
              categories={problemDetails.categories ?? []}
              description={problemDetails.description}
              visible={problemDetails.visible ? 'public' : 'private'}
              test={problemDetails.tests}
              modules_ids={problemDetails.modules_ids}
              isEdit={true}
              url={`${URLs.updateProblem}/${problemDetails.id}`}
              method="put"
              onSuccess={() => window.location.reload()}
              onClose={() => (wasDataEdited ? setCloseConfirmation(true) : others.onClose())}
              dataWasEditedCallback={() => setWasDataEdited(true)}
            />
          )}
        </div>
      </Modal>
      {closeConfirmation && (
        <Modal open={closeConfirmation}>
          <div className="flex flex-col items-center gap-3 bg-background-default top-2/4 left-2/4 w-[35%] h-fit px-4 py-5 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <Typography variant="h4">Confirm action</Typography>
            <Typography variant="subtitle2">
              You are about to leave the menu without saving. Any unsaved changes will be lost. Do you want to proceed?
            </Typography>

            <div className="flex flex-row gap-5 mt-2 items-center justify-center">
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  others.onClose();
                  setCloseConfirmation(false);
                }}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                color="gridPrimary"
                onClick={() => {
                  setCloseConfirmation(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default UpdateProblemModalWrapper;
