import {Grid, Typography} from '@mui/material';

import {Result as RestultComponent} from './Result';
import {Switch} from '../../switch/Switch';
import {Result} from '../../../types';

export interface ResultsProps {
  editor: {
    height: number;
  };
  results: Array<Result>;
}

export const Results = (props: ResultsProps) => (
  <Grid container>
    <Switch
      direction="vertical"
      options={props.results.map((result, index) => ({
        component: <RestultComponent result={result} />,
        name: <Typography variant="h6">{`Test ${index + 1}`}</Typography>,
      }))}
      variant="contained"
    />
  </Grid>
);
