import {format} from 'date-fns';

export const formatDate = (dateString: string) => format(new Date(dateString), 'ccc d MMM yyyy, H:mm:ss');

export const fromDate = (date: Date): string => format(date, 'eeee, MMM d, yyyy, H:mm');

export const stringInterval = (start: Date, end: Date): string => `${fromDate(start)} - ${fromDate(end)}`;


export const formatMMSS = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.round(seconds % 60);
  const t = [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter(Boolean).join(':');
  return seconds * 1000 < 0 && seconds ? `-${t}` : t;
};

export const fromDateToSmall = (date: Date): string => format(date, 'dd-MM-yy hh_mm_ss a');

