import {useState} from 'react';

import {Modal, Typography, useTheme} from '@mui/material';

import {ContestSubmission} from '../../../types/entities/ContestSubmission';
import {Problem} from '../../../types';
import {CodeEditor} from '../../code-editor/CodeEditor';
import {SelectedTestView} from './../submission-modal-utils/SelectedTestView';
import {CompilationErrorBox} from './../submission-modal-utils/CompilationErrorBox';
import {ResultsBox} from './../submission-modal-utils/ResultsBox';

export type SubmissionModalProps = {
  submission: ContestSubmission;
  problem: Problem;
  onClose: () => void;
};
export const SubmissionModal = ({problem, submission, onClose}: SubmissionModalProps) => {
  const theme = useTheme();

  const [selectedTestIdx, setSelectedTestIdx] = useState<number | undefined>(undefined);

  const allTestsPassed = (): boolean =>
    submission &&
    submission.result.compiled &&
    submission.result.results &&
    submission.result.results.filter((el) => el.status === 'FAILED').length === 0;

  const passed = allTestsPassed();

  const onTestClick = (index: number) => {
    if (selectedTestIdx === undefined || selectedTestIdx !== index) {
      setSelectedTestIdx(index);
    }
  };

  return (
    <Modal open={true} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[90%] h-[90%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
        <Typography
          color={theme.palette.secondary.light}
          variant="subtitle1"
        >{`This ${problem.title} submission belongs to ${submission.userFirstName} ${submission.userLastName}`}</Typography>
        <div className="flex justify-between items-center">
          <Typography
            onClick={() => {
              setSelectedTestIdx(undefined);
            }}
            sx={{color: selectedTestIdx == undefined ? '#FFFFFF' : '#808080'}}
            className="cursor-pointer"
          >
            Submitted code
          </Typography>

          <div className="flex">
            <Typography fontWeight="bold" my={1} mx={2}>{`Score: ${submission.grade}`}</Typography>
            <Typography fontWeight="bold" my={1}>
              Status:
            </Typography>
            <Typography
              fontWeight="bold"
              color={passed ? theme.palette.secondary.light : theme.palette.error.main}
              ml={1}
              my={1}
            >
              {`${passed ? ' Accepted' : 'Failed'}`}
            </Typography>
          </div>
        </div>
        {!submission.result.compiled && <CompilationErrorBox error={submission.result.error} />}
        {submission.result.results && (
          <ResultsBox selectedTest={selectedTestIdx} results={submission.result.results} onClick={onTestClick} />
        )}
        {selectedTestIdx !== undefined && (
          <SelectedTestView
            testIndex={selectedTestIdx}
            result={submission.result.results[selectedTestIdx]}
            onClick={() => setSelectedTestIdx(undefined)}
          />
        )}
        {selectedTestIdx === undefined && (
          <CodeEditor className="min-h-[60vh]" code={submission.code} readOnly={true} enableMinimap />
        )}
      </div>
    </Modal>
  );
};
