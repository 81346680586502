import {useState, useReducer, useEffect, useRef} from 'react';

import {Checkbox, Tooltip, Typography} from '@mui/material';

import {ButtonSize as Size, Color, InputProps, ButtonSize} from '../../../types';
import {File} from '../file/File';
import {updateProperty} from '../../../utils';
import {Button} from '../../button/Button';
import {Error} from '../error/Error';
import {Test as TestType} from '../../../types/entities/Test';
import {theme} from '../../../types/theme/Theme';

export const Test = (props: InputProps) => {
  const error = props.error ?? false;

  const [tests, setTests] = useState<Array<Record<string, any>>>(props.value ?? []);
  const [maxNoTestsLeft, setMaxNoTestsLeft] = useState(props.maxNoEntries ? props.maxNoEntries - tests.length : 0);
  const [allVisible, setAllVisible] = useState<boolean>(
    props.value ? props.value.every((test: TestType) => test.isVisible) : false
  );
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const isInitialRender = useRef(true);

  useEffect(() => {
    // Skip calling onChange during the first render
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (props.onChange) {
      props.onChange(null, tests);
    }
  }, [tests]);

  const onChange = (testIdx: number) => (field: string, value: any) => {
    const newTests = [...tests];
    newTests[testIdx] = updateProperty(newTests[testIdx], field, value);
    setTests(newTests);
    if (props.onChange) {
      props.onChange(null, newTests);
    }
    forceUpdate();
  };

  const addNewTest = () => {
    setTests([
      ...tests,
      {
        grade: '',
        input: '',
        output: '',
        isVisible: allVisible ? true : false,
      },
    ]);
    setMaxNoTestsLeft(maxNoTestsLeft - 1);
    forceUpdate();
  };

  const removeTest = (testIdx: number) => {
    const newTests = [...tests];
    newTests.splice(testIdx, 1);

    setTests(newTests);
    setMaxNoTestsLeft(maxNoTestsLeft + 1);
    forceUpdate();
  };

  const toggleAllVisible = () => {
    const newTests = tests.map((test) => ({...test, isVisible: !allVisible}));
    setTests(newTests);
    setAllVisible(!allVisible);
  };

  const options: {name: string; label: string}[] = [
    {name: 'grade', label: 'grade'},
    {name: 'input', label: 'input'},
    {name: 'output', label: 'expected output'},
  ];

  return (
    <>
      <Typography variant="h6" sx={{marginBottom: '10px', marginTop: '10px'}}>
        {props.title}
      </Typography>

      <div className="flex flex-row items-center">
        <Checkbox checked={allVisible} onChange={() => toggleAllVisible()} />
        <Typography variant="subtitle2">Display all tests in problem statement</Typography>
      </div>

      {tests.map((test, testIndex) => (
        <div key={testIndex}>
          <div className="flex flex-col justify-center content-center gap-2 mb-5 w-full">
            <Typography variant="h5" sx={{marginTop: '10px'}}>
              {`Test ${testIndex + 1}`}
            </Typography>
            <div className="flex flex-row justify-between align-middle mr-3">
              <Tooltip
                title={
                  testIndex === 0
                    ? 'You cannot hide the first test'
                    : allVisible
                    ? 'All tests are visible, to change this uncheck the toggle above.'
                    : ''
                }
                arrow
              >
                <div className="flex flex-row items-center">
                  <Checkbox
                    checked={testIndex === 0 ? true : test.isVisible}
                    disabled={testIndex === 0 || allVisible}
                    onChange={() => onChange(testIndex)('isVisible', !test.isVisible)}
                    sx={{
                      '&.Mui-disabled': {
                        color: theme.palette.grey[500],
                      },
                    }}
                  />
                  <Typography variant="subtitle2">Display in problem statement</Typography>
                </div>
              </Tooltip>
              <Button
                label={'X'}
                size={ButtonSize.SMALL}
                color={Color.PRIMARY}
                onClick={() => removeTest(testIndex)}
                style={{padding: '2px', minWidth: '42px'}}
              />
            </div>
            <div className="flex flex-row gap-3 w-full">
              {options.map((option, optionIndex) => (
                <div key={`${testIndex}-${optionIndex}`} className="w-full">
                  <Typography variant="subtitle2">{option.label.toLocaleUpperCase()}</Typography>
                  <File
                    fullWidth={true}
                    id={option.name}
                    testNumber={testIndex}
                    onChange={onChange(testIndex)}
                    placeholder={`# ${option} ${props.id}${testIndex + 1}`}
                    rules={{}}
                    value={test ? String(test[option.name]) : ''}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      {error !== false && (
        <div className="mb-2">
          <Error id={props.id} message={error} />
        </div>
      )}
      {maxNoTestsLeft !== undefined && maxNoTestsLeft > 0 && (
        <Button color={Color.GRID_PRIMARY} label={`Add ${props.id}`} onClick={addNewTest} size={Size.LARGE} />
      )}
    </>
  );
};
