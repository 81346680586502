import {useState} from 'react';

import {Grid, IconButton, Tooltip} from '@mui/material';
import {Editor} from '@monaco-editor/react';
import {OpenInFull, CloseFullscreen} from '@mui/icons-material';

import {ResultProps} from './Result.props';
import {DiffViewer} from './DiffViewer';

export const Result = ({result}: ResultProps) => {
  const [hideInput, setHideInput] = useState<boolean>(false);

  return (
    <Grid container direction="row" justifyContent="center" spacing={1} sx={{marginBottom: '20px', width: '100%'}}>
      <div className="flex w-full h-[400px]">
        {hideInput ? (
          <div className="flex flex-col h-[440px]">
            <div className="bg-[#161616] flex flex-row ju ify-between py-[2px]">
              <Tooltip title={`${hideInput ? 'Show ' : 'Hide '} input`}>
                <IconButton onClick={() => setHideInput(!hideInput)} aria-label="delete">
                  {hideInput ? (
                    <OpenInFull fontSize="small" color="primary" />
                  ) : (
                    <CloseFullscreen fontSize="small" color="primary" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className="bg-[#1E1E1E] h-full" />
          </div>
        ) : (
          <div className="flex w-1/3 flex-col h-[440px]">
            <div className="bg-[#161616] flex flex-row justify-between">
              <div className="ml-7 py-2">Input</div>
              <Tooltip title={`${hideInput ? 'Show ' : 'Hide '} input`}>
                <IconButton onClick={() => setHideInput(!hideInput)} aria-label="delete">
                  {hideInput ? (
                    <OpenInFull fontSize="small" color="primary" />
                  ) : (
                    <CloseFullscreen fontSize="small" color="primary" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <Editor
              language="java"
              theme="vs-dark"
              value={result.input ?? 'The input is unavailable.'}
              options={{
                readOnly: true,
                scrollBeyondLastLine: false,
              }}
            />
          </div>
        )}
        <DiffViewer
          output={result.out}
          expected={result.ref}
          titles={{outputTitle: 'Current output', expectedTitle: 'Expected output'}}
        />
      </div>
    </Grid>
  );
};
