import {FileDownload} from '@mui/icons-material';
import {Button, IconButton, Tooltip} from '@mui/material';

import {fromDateToSmall} from '../../../utils/date-utils';
import {ContestSubmission} from '../../../types/entities/ContestSubmission';
import {Problem} from '../../../types';
import {ContestViewData} from '../../../api/responses/ContestViewResponseTypes';
import {sendGetRequest, URLs} from '../../../api';
import {exportDownloadCSV} from '../../../utils/CsvHelper';

type SubmissionsExportButtonProps = {
  contest: ContestViewData;
  groupContest: number;
  searchParams: {
    problemId?: number;
    userId?: number;
    limit?: number;
    offset?: number;
    latest?: boolean;
    duringContest?: boolean;
  };
};

function SubmissionsExportButton(props: SubmissionsExportButtonProps) {
  const exportSubmissions = async () => {
    const searchParams = new URLSearchParams();

    let problems: {[id: number]: Problem} | null = null;
    let submissions: Array<ContestSubmission> = [];

    Object.entries({
      ...props.searchParams,
      limit: 15000, // TODO: refactor the submissions request (to paginated) to send NO. of all submissions and replace this with that number (see leaderboard)
    }).forEach(([key, value]) => {
      if (value != null) {
        searchParams.append(key, value.toString());
      }
    });

    await sendGetRequest(
      `${URLs.getContestSubmissions(Number(props.contest.id))}?groupContestId=${
        props.groupContest
      }&${searchParams.toString()}`
    ).then((response) => {
      submissions = response.data.submissions;
      problems = response.data.problems;
    });

    const headers = [
      'email',
      'full name',
      'problem',
      'status',
      'points',
      'correctness',
      'submitted-at',
      'during-contest',
      'code',
    ];

    if (submissions !== null && problems !== null) {
      const data = submissions.map((submission: ContestSubmission) => {
        const problem = (problems ? Object.values(problems) : []).find(
          (problem) => problem.id === submission.problemId
        );
        return {
          email: submission.userEmail,
          'full name': `${submission.userFirstName} ${submission.userLastName}`,
          problem: problem?.title || 'Unknown problem name',
          status: submission.result.compiled
            ? submission.result.results != null &&
              submission.result.results.every((result) => result.status === 'PASSED')
              ? 'Compiled'
              : 'Wrong answer'
            : 'Compilation error',
          points: String(submission.grade),
          correctness:
            submission.result.results != null
              ? `${(
                  (submission.result.results.filter((result) => result.status === 'PASSED').length /
                    submission.result.results.length) *
                  100
                ).toFixed(2)}%`
              : '-',
          'submitted-at': fromDateToSmall(new Date(submission.date)),
          'during-contest':
            new Date(submission.date).getTime() > new Date(props.contest.start_date).getTime() &&
            new Date(submission.date).getTime() < new Date(props.contest.end_date).getTime()
              ? 'Yes'
              : 'No',
          code: `"${submission.code.replace(/(\r\n|\r|\n)/g, '\\n').replace(/"/g, '""')}"`,
        };
      });

      exportDownloadCSV(headers, data, `Submissions for ${props.contest.title} - ${fromDateToSmall(new Date())}`);
    }
  };

  return (
    <>
      <Tooltip title="Exports all submission entries based on the selected filters">
        <Button
          startIcon={<FileDownload />}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => exportSubmissions()}
        >
          Export to CSV
        </Button>
      </Tooltip>
    </>
  );
}

export default SubmissionsExportButton;
