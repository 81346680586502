import {useContext, useEffect, useState, useReducer} from 'react';
import {useNavigate, useOutletContext, useSearchParams} from 'react-router-dom';

import {Skeleton} from '@mui/material';

import {URLs, sendGetRequest} from '../../api';
import {LeaderboardEntryResponse, LeaderboardResponse} from '../../api/responses/LeaderboardEntiesResponseTypes';
import AppContext from '../../context/AppContext';
import LeaderboardTable from '../contest-card/leaderboard/LeaderboardTable';
import {ContestContextType} from '../../pages/contest/Contest';
import {fromDateToSmall} from '../../utils/date-utils';
import {exportDownloadCSV} from '../../utils/CsvHelper';

export type LeaderboardPaginationState = {
  page: number;
  rowsPerPage: number;
};

type PaginationAction = {type: 'UPDATE_PAGE_NO'; payload: number} | {type: 'UPDATE_ROWS_PER_PAGE'; payload: number};

const filterReducer = (state: LeaderboardPaginationState, action: PaginationAction): LeaderboardPaginationState => {
  switch (action.type) {
    case 'UPDATE_PAGE_NO':
      return {...state, page: action.payload};
    case 'UPDATE_ROWS_PER_PAGE':
      return {...state, rowsPerPage: action.payload};
    default:
      return {...state};
  }
};

type LeaderboardProps = {
  contestId: number;
  groupContestId: number;
};

export const Leaderboard = ({contestId, groupContestId}: LeaderboardProps) => {
  const {dispatchError, user} = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {isOwner, contest} = useOutletContext<ContestContextType>();

  const initialState = {
    page: Number(searchParams.get('page')) ? Number(searchParams.get('page')) : 0,
    rowsPerPage: Number(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 10,
  };

  const [state, dispatch] = useReducer(filterReducer, initialState);
  const [loading, setLoading] = useState<boolean>(true);

  const handlePageNoChange = (value: number) => {
    dispatch({type: 'UPDATE_PAGE_NO', payload: value});
  };

  const handleRowsPerPageChange = (value: number) => {
    dispatch({type: 'UPDATE_ROWS_PER_PAGE', payload: value});
    handlePageNoChange(0);
  };

  const [leaderboardRowsResponse, setLeaderboardRowsResponse] = useState<LeaderboardResponse | undefined>(undefined);

  const goToSubmissions = (userId: number) => (_event: any) => {
    isOwner && navigate(`/contest/${contestId}/${groupContestId}/submissions?userId=${userId}`);
  };

  useEffect(() => {
    const filters: Array<{key: string; value: number | boolean | string}> = [];

    if (state.page) {
      filters.push({key: 'page', value: state.page});
    }
    if (state.rowsPerPage) {
      filters.push({key: 'limit', value: state.rowsPerPage});
    }

    const updatedParams = new URLSearchParams();
    filters.forEach((filter) => {
      updatedParams.set(filter.key, filter.value.toString());
    });
    setSearchParams(updatedParams, {replace: true});

    setLoading(true);

    sendGetRequest(
      URLs.contestLeaderboard(
        contestId,
        groupContestId,
        filters.map((filter) => `${filter.key}=${filter.value}`).join('&')
      )
    )
      .then((response) => {
        setLeaderboardRowsResponse(response.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.error, redirectURL: '/'});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later', redirectURL: '/'});
          }
        }
      });
  }, [state]);

  // (Eduard): Unused for now, might be used in the future
  const exportAllGrades = async () => {
    if (leaderboardRowsResponse) {
      let rankingsExport: Array<LeaderboardEntryResponse> = [];

      await sendGetRequest(
        URLs.contestLeaderboard(contestId, groupContestId, `limit=${leaderboardRowsResponse.metadata.total}`)
      )
        .then((response) => {
          rankingsExport = response.data.rankings;
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.data && err.response.data.error) {
              dispatchError({errorMessage: err.response.data.error, redirectURL: '/'});
            } else {
              dispatchError({errorMessage: 'There was an error. Please try again later', redirectURL: '/'});
            }
          }
        });
      const headers = ['ranking', 'full name', 'user-name', 'grade'];

      if (rankingsExport) {
        const data = rankingsExport.map((ranking) => ({
          ranking: String(ranking.ranking),
          'full name': `${ranking.firstName} ${ranking.lastName}`,
          'user-name': ranking.userName,
          grade: String(ranking.points),
        }));

        exportDownloadCSV(headers, data, `Grades for ${contest.title} - ${fromDateToSmall(new Date())}`);
      }
    }
  };

  return (
    <div>
      {loading && (
        <>
          <div className="flex flex-col items-end">
            <Skeleton animation="pulse" variant="rectangular" sx={{width: '100%', height: '56px'}} />
            <Skeleton
              animation="pulse"
              variant="rectangular"
              sx={{width: '100%', height: `${Math.min(53 * state.rowsPerPage, 530)}px`, mt: '18px'}}
            />
          </div>
        </>
      )}
      {leaderboardRowsResponse && !loading && (
        <div className="flex flex-col justify-between h-full">
          <LeaderboardTable
            entriesRows={leaderboardRowsResponse.rankings}
            onClick={goToSubmissions}
            setRowsPerPage={handleRowsPerPageChange}
            setPage={handlePageNoChange}
            totalNoRankings={leaderboardRowsResponse.metadata.total}
            state={state}
            // exportGradesCallback={() => exportAllGrades()}
          />
        </div>
      )}
    </div>
  );
};
