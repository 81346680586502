import {useState} from 'react';

import {Editor} from '@monaco-editor/react';
import {OpenInFull, CloseFullscreen} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';

import {Result} from '../../../types';
import {DiffViewer} from '../../submissions/results/DiffViewer';

export type SelectedTestViewProps = {
  testIndex: number;
  onClick: () => void;
  result: Result;
};

export const SelectedTestView = ({result}: SelectedTestViewProps) => {
  const [hideInput, setHideInput] = useState<boolean>(false);

  return (
    <>
      <div className="flex w-full min-h-[200px]">
        {hideInput ? (
          <div className="flex flex-col">
            <div className="bg-[#161616] flex flex-row ju ify-between py-[2px]">
              <Tooltip title={`${hideInput ? 'Show ' : 'Hide '} input`}>
                <IconButton onClick={() => setHideInput(!hideInput)} aria-label="delete">
                  {hideInput ? (
                    <OpenInFull fontSize="small" color="primary" />
                  ) : (
                    <CloseFullscreen fontSize="small" color="primary" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className="bg-[#1E1E1E] h-full" />
          </div>
        ) : (
          <div className="flex w-1/3 flex-col">
            <div className="bg-[#161616] flex flex-row justify-between">
              <div className="ml-7 py-2">Input</div>
              <Tooltip title={`${hideInput ? 'Show ' : 'Hide '} input`}>
                <IconButton onClick={() => setHideInput(!hideInput)} aria-label="delete">
                  {hideInput ? (
                    <OpenInFull fontSize="small" color="primary" />
                  ) : (
                    <CloseFullscreen fontSize="small" color="primary" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <Editor
              language="java"
              theme="vs-dark"
              value={result.input ?? 'The input is unavailable.'}
              options={{
                readOnly: true,
                scrollBeyondLastLine: false,
              }}
            />
          </div>
        )}

        <div className="overflow-scroll flex w-full">
          <DiffViewer
            expected={result.ref}
            output={result.out}
            titles={{outputTitle: 'Current output', expectedTitle: 'Expected output'}}
          />
        </div>
      </div>
    </>
  );
};
