import {useContext, useEffect, useState} from 'react';

import {CircularProgress, Grid} from '@mui/material';

import {sendPostRequest, URLs} from '../../api';
import AppContext from '../../context/AppContext';
import {Submission} from '../../types/';
import {Card} from './card/Card';
import {useStorage} from '../../hooks';

export interface SubmissionsProps {
  contestId: number;
  problemId: number;
  setCode: (newCode: string, newLanguageId: number) => void;
  pending?: boolean;
  latestSubmission?: Submission;
}

export const Submissions = (props: SubmissionsProps) => {
  const {dispatchError, user} = useContext(AppContext);

  const contestId = isNaN(props.contestId) ? -1 : props.contestId;

  const [submissions, setSubmissions] = useState<Array<Submission>>([]);

  const withoutContest = `pending_c-1_p${props.problemId}`;
  const inContest = `pending_c${contestId}_p${props.problemId}`;
  const pendingWithoutContest = useStorage(withoutContest);
  const pendingInContest = useStorage(inContest);

  useEffect(() => {
    if (props.latestSubmission && (submissions[0]?.id !== props.latestSubmission.id || submissions.length === 0)) {
      setSubmissions([props.latestSubmission, ...submissions]);
    }
  }, [props.latestSubmission]);

  useEffect(() => {
    if (props.problemId) {
      sendPostRequest(`${URLs.getSubmissions(props.problemId)}`)
        .then((response) => {
          const allSubmissions = Submission.fromArrayResponse(response.data);
          if (pendingWithoutContest || pendingInContest) {
            allSubmissions.unshift(Submission.PENDING);
          }
          setSubmissions(allSubmissions);
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.data && err.response.data.error) {
              dispatchError({errorMessage: err.response.data.error, redirectURL: '/'});
            } else {
              dispatchError({errorMessage: 'There was an error. Please try again later', redirectURL: '/'});
            }
          }
        });
    }
  }, [props.problemId, user, pendingWithoutContest, pendingInContest]);

  return (
    <>
      <div className="flex flex-col gap-1 ">
        <>
          {props.pending && (
            <Grid key="loading-submission" item style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress color="success" />
            </Grid>
          )}
          {submissions.map((submission: Submission, idx: number) => (
            <Grid key={`submission-${idx}`} item>
              <Card index={submissions.length - idx} submission={submission} setCode={props.setCode} />
            </Grid>
          ))}
        </>
      </div>
    </>
  );
};
