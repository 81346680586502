import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useOutletContext, useParams, useSearchParams} from 'react-router-dom';

import {Button, InputLabel, MenuItem, Select, Switch, Tooltip} from '@mui/material';
import {RotateLeft} from '@mui/icons-material';

import {sendGetRequest, URLs} from '../../../api';
import AppContext from '../../../context/AppContext';
import {Problem, Role, User} from '../../../types';
import {ContestSubmission} from '../../../types/entities/ContestSubmission';
import {ContestContextType} from '../../../pages/contest/Contest';
import SubmissionsTable from '../submissions-table/SubmissionsTable';
import {BASE_URL} from '../../../api/API';
import SubmissionsExportButton from './SubmissionsExportButton';

export const ContestSubmissions = () => {
  const {contest} = useOutletContext<ContestContextType>();
  const {id: contestId, groupContest} = useParams();
  const [submissions, setSubmissions] = useState<Array<ContestSubmission>>([]);
  const [problems, setProblems] = useState<{[id: number]: Problem} | undefined>(undefined);
  const [users, setUsers] = useState<User[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const context = useContext(AppContext);

  const {offset, limit, problemId, userId} = useMemo(() => {
    const offset = Number(searchParams.get('offset')) || 0;
    const limit = Number(searchParams.get('limit')) || 10;

    const problemId = Number(searchParams.get('problemId')) || undefined;
    const userId = Number(searchParams.get('userId')) || undefined;

    return {offset, limit, problemId, userId};
  }, [searchParams]);

  useEffect(() => {
    if (contest?.group_id) {
      sendGetRequest(URLs.getUsersInGroup(contest.group_id))
        .then((response: any) => {
          setUsers(
            response.data.users.sort((a: User, b: User) => {
              const aFullName = `${a.lastName} ${a.firstName}`;
              const bFullName = `${b.lastName} ${b.firstName}`;

              return aFullName.localeCompare(bFullName);
            })
          );
        })
        .catch((err: any) => {
          // TODO: Error management ;(
          console.error(err);
        });
    }
  }, [contest]);

  useEffect(() => {
    sendGetRequest(
      `${URLs.getContestSubmissions(Number(contestId))}?groupContestId=${groupContest}&${searchParams.toString()}`
    ).then((response) => {
      setSubmissions(response.data.submissions);
      setProblems(response.data.problems);
    });
  }, [contestId, groupContest, searchParams]);

  const prevPage = useCallback(() => {
    searchParams.set('offset', (offset - limit).toString());
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const nextPage = useCallback(() => {
    searchParams.set('offset', (offset + limit).toString());
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const filterByProblem = useCallback(
    (problemId: number) => {
      if (isNaN(problemId)) {
        searchParams.delete('problemId');
      } else {
        searchParams.set('problemId', problemId.toString());
      }

      searchParams.delete('offset');
      setSearchParams(searchParams, {replace: true});
    },
    [searchParams]
  );

  const filterByUser = useCallback(
    (userId: number) => {
      if (isNaN(userId)) {
        searchParams.delete('userId');
      } else {
        searchParams.set('userId', userId.toString());
      }

      searchParams.delete('offset');
      setSearchParams(searchParams, {replace: true});
    },
    [searchParams]
  );

  const applyLatestSubmissionsFilter = useCallback(() => {
    const latest = searchParams.get('latest') === 'true';
    searchParams.set('latest', (!latest).toString());
    searchParams.delete('offset');
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const applyDuringContestFilter = useCallback(() => {
    const latest = searchParams.get('duringContest') === 'true';
    searchParams.set('duringContest', (!latest).toString());
    searchParams.delete('offset');
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const modalRoute = (
    contestId: number,
    groupContestId: string | undefined,
    submissionId: number,
    searchParams: URLSearchParams
  ) => `/contest/${contestId}/${groupContestId}/submissions/${submissionId}?${searchParams.toString()}`;

  if (!contest) {
    return <div>Contest not found</div>;
  }

  return (
    <div className="flex flex-col items-center h-full">
      <div className="flex flex-col items-center gap-3 max-h-full w-[90%] overflow-scroll">
        <div className="flex justify-between w-full align-middle">
          <div className="flex gap-4 items-center w-full">
            <div className="flex items-center gap-1">
              <InputLabel id="filter-problem-dropdown-label"> Problem </InputLabel>
              <Select
                label="Filter by Problem"
                labelId="filter-problem-dropdown-label"
                id="filter-problem-dropdown"
                value={searchParams.get('problemId') || 'all'}
                onChange={(e) => {
                  filterByProblem(parseInt(e.target.value, 10));
                }}
              >
                <MenuItem value="all"> All </MenuItem>
                {contest.problems.map((problem) => (
                  <MenuItem value={problem.id}> {problem.title} </MenuItem>
                ))}
              </Select>
            </div>

            <div className="flex items-center gap-1">
              <InputLabel id="filter-user-dropdown-label"> User </InputLabel>
              <Select
                label="Filter by User"
                labelId="filter-user-dropdown-label"
                id="filter-user-dropdown"
                value={searchParams.get('userId') || 'all'}
                onChange={(e) => {
                  filterByUser(parseInt(e.target.value, 10));
                }}
              >
                <MenuItem value="all"> All </MenuItem>
                {users.map((user) => (
                  <MenuItem value={user.id}>
                    {user.lastName} {user.firstName}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="flex items-center gap-1">
              <p className="mr-[-4px] whitespace-nowrap"> Last Submission </p>
              <Switch
                checked={searchParams.get('latest') === 'true'}
                inputProps={{'aria-label': 'Size switch demo'}}
                onChange={() => applyLatestSubmissionsFilter()}
              />
            </div>
            <div className="flex items-center gap-1">
              <p className="mr-[-4px] whitespace-nowrap"> During Contest </p>
              <Switch
                checked={searchParams.get('duringContest') === 'true'}
                inputProps={{'aria-label': 'Size switch demo'}}
                onChange={() => applyDuringContestFilter()}
              />
            </div>
            <div className="flex flex-row justify-between gap-3 w-full">
              <div className="flex flex-col gap-1">
                {context.user?.role === Role.ADMIN && (
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${BASE_URL}${URLs.getMakeContestSubmissions(
                            Number(contestId)
                          )}?groupContestId=${groupContest}&includeAll=true`
                        );
                      }}
                    >
                      Copy Make Link
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText('Bearer ' + context.user?.token ?? 'No valid token');
                      }}
                    >
                      Copy Auth Token
                    </Button>
                  </>
                )}
              </div>
              <div className="flex flex-row items-center gap-2">
                {context.user &&
                  (context.user.role === Role.ADMIN ||
                    context.user.role === Role.COURSE_ADMIN ||
                    context.user.role === Role.AUTHOR_TEACHER ||
                    context.user.role === Role.TEACHER) && (
                    <SubmissionsExportButton
                      groupContest={Number(groupContest)}
                      searchParams={{
                        problemId,
                        userId,
                        limit,
                        offset,
                        latest: searchParams.get('latest') === 'true',
                        duringContest: searchParams.get('duringContest') === 'true',
                      }}
                      contest={contest}
                    />
                  )}

                <Tooltip title="Reset filters">
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setSearchParams({}, {replace: true});
                    }}
                  >
                    <RotateLeft />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        {(context.user?.role === Role.ADMIN ||
          context.user?.role === Role.TEACHER ||
          context.user?.role === Role.COURSE_ADMIN ||
          context.user?.role === Role.AUTHOR_TEACHER) && (
          <SubmissionsTable
            submissions={submissions}
            problems={problems}
            contest={contest}
            groupContest={groupContest}
            searchParams={searchParams}
            modalRoute={modalRoute}
            pagination={{prevPage, nextPage, offset, limit}}
            filterByProblem={filterByProblem}
            filterByUser={filterByUser}
          />
        )}
      </div>
    </div>
  );
};
