import {useContext, useEffect, useMemo, useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';

import {AdminPanelSettings, ArrowBackIosNew, Edit} from '@mui/icons-material';
import {IconButton, Skeleton} from '@mui/material';

import {CodeEditor} from '../code-editor/CodeEditor';
import {createSubmissionRequest} from '../../api/Requests';
import {request, sendGetRequest, sendPostRequest, URLs} from '../../api';
import {ModalWithButton, Switch} from '..';
import {Submissions} from '../submissions/Submissions';
import {Role, Submission} from '../../types';
import {Description} from './Description';
import AppContext, {ContextData} from '../../context/AppContext';
import {dropdownStylesConfig} from '../form/dropdown/Dropdown';
import {languageOptions} from '../../types/entities/Language';
import AuthorsManagement from '../authors/AuthorsManagement';
import ConfirmationModal from '../modal/ConfirmationModal';
import {ViewProblemAuthorsResponse} from '../../api/responses/AuthorsResponseTypes';
import {formatMMSS} from '../../utils/date-utils';
import UpdateProblemModalWrapper from './UpdateProblemModalWrapper';
import {ProblemContestViewResponse} from '../../api/responses/ProblemResponseTypes';

export interface CardProps {
  problemId: number;
  contestId: number;
  groupContestId: number;
}

export const Card = ({problemId, contestId, groupContestId}: CardProps) => {
  const {dispatchError, user, checkTimeLimit} = useContext(AppContext);
  const navigate = useNavigate();

  const dropdownStyles = dropdownStylesConfig();

  const [code, setCode] = useState<string | null>(null);
  const [problem, setProblem] = useState<ProblemContestViewResponse | null>(null);
  const [switchTo, setSwitchTo] = useState<number | undefined>(undefined);
  const [pending, setPending] = useState<boolean>(false);
  const [latestSubmission, setLatestSubmission] = useState<Submission>();
  const [selectedLanguage, setSelectedLanguage] = useState<{languageId: number; skeleton: string} | null>(null);
  const [submissionTimeout, setSubmissionTimeout] = useState<number | null>(null);

  useEffect(() => {
    const localTimeoutExpiration = localStorage.getItem(`timeout-expiration-${problemId}-${groupContestId}`);

    if (localTimeoutExpiration !== null) {
      const secondsToTimeoutExpiration = Math.ceil((Number(localTimeoutExpiration) - Date.now()) / 1000);
      if (secondsToTimeoutExpiration > 0) {
        setSubmissionTimeout(secondsToTimeoutExpiration);
      } else {
        localStorage.removeItem(`timeout-expiration-${problemId}-${groupContestId}`);
      }
    }
  }, []);

  useEffect(() => {
    if (submissionTimeout !== null && submissionTimeout > 0) {
      const timerInterval = setInterval(() => {
        setSubmissionTimeout((prevTime) => {
          if (prevTime == 1 || prevTime == null) {
            clearInterval(timerInterval);
            localStorage.removeItem(`submission-${problemId}-${groupContestId}`);
            return null;
          } else {
            return prevTime - 1;
          }
        });
      }, 999);
      return () => clearInterval(timerInterval);
    }
  }, [submissionTimeout]);

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);
  const [languageToSet, setLanguageToSet] = useState<{languageId: number; skeleton: string} | null>(null);
  const [submissionToRestore, setSubmissionToRestore] = useState<{code: string; language_id: number} | null>(null);

  const [editMode, setEditMode] = useState(false);
  const [adminPanelOpen, setAdminPanelModel] = useState(false);
  const toggleAdminPanel = () => setAdminPanelModel((prev) => !prev);

  const isPrivileged = useMemo(() => {
    if (!user?.id) return false;

    let currentUserId = user.id;

    if (typeof currentUserId === 'string') {
      currentUserId = parseInt(currentUserId, 10);
    }

    return (
      user?.role === Role.ADMIN ||
      user?.role === Role.CREATOR ||
      (user?.role === Role.AUTHOR && problem?.authorsIds.find((id) => id == currentUserId)) ||
      user?.role === Role.COURSE_ADMIN ||
      (user?.role === Role.AUTHOR_TEACHER && problem?.authorsIds.find((id) => id == currentUserId))
    );
  }, [problem, user]);

  const pendingKey = useMemo(() => `pending_c${contestId}_p${problemId}`, [contestId, problemId]);

  const onSubmit = () => {
    if (!problem) return;

    const request = {
      code: code,
      languageId: selectedLanguage?.languageId,
    } as createSubmissionRequest;

    if (Number(problem.contestTimeout) !== 0) {
      setSubmissionTimeout(problem.contestTimeout);

      localStorage.setItem(
        `timeout-expiration-${problemId}-${groupContestId}`,
        JSON.stringify(Date.now() + problem.contestTimeout * 1000)
      );
    }

    setSwitchTo(1);
    localStorage.setItem(pendingKey, new Date().toString());

    // Normally, the storage event is dispatched only if the change was done from a different page
    window.dispatchEvent(new Event('storage'));
    setPending(true);

    setTimeout(() => {
      const pending = localStorage.getItem(pendingKey);
      if (pending) {
        checkTimeLimit(pending, pendingKey);
      } else {
        setPending(false);
      }
    }, ContextData.TIME_LIMIT);

    sendPostRequest(URLs.createSubmission(problem.id, contestId, groupContestId), request)
      .then((_response) => {
        // Navigate to the "Submissions" tab
        localStorage.removeItem(pendingKey);
        window.dispatchEvent(new Event('storage'));
        setPending(false);
        setLatestSubmission(Submission.fromResponse(_response.data));
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.error});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later'});
          }
        }
      });
  };

  const loadProblemAuthors = async (callback: () => void) => {
    let response: ViewProblemAuthorsResponse = [];
    await request<{}, {}, ViewProblemAuthorsResponse>(URLs.getProblemAuthors(problemId), {
      method: 'GET',
      successCallback: (r) => {
        response = r;
        callback();
      },
      errorCallback: (e: any) => {
        dispatchError({
          errorMessage: e.response.data.error ?? 'There was an error. Please try again later.',
          redirectURL: '/problems',
        });
      },
    });
    return response;
  };

  const assignAuthor = (authorEmail: string, callback: () => void) => {
    request<{}, {authorEmail: string}, {}>(URLs.assignAuthorToProblem(problemId), {
      method: 'POST',
      body: {authorEmail},
      successCallback: () => {
        callback();
        forceUpdate();
      },
      errorCallback: (e: any) => {
        dispatchError({errorMessage: e.response.data.error ?? 'There was an error. Please try again later.'});
      },
    });
  };

  const unassignAuthor = (authorId: number, callback: () => void) => {
    request<{}, {}, {}>(URLs.unassignAuthorFromProblem(problemId, authorId), {
      method: 'DELETE',
      successCallback: () => {
        callback();
        forceUpdate();
      },
      errorCallback: (e: any) => {
        dispatchError({errorMessage: e.response.data.error ?? 'There was an error. Please try again later.'});
      },
    });
  };

  useEffect(() => {
    sendGetRequest(groupContestId > 0 ? URLs.getContestProblem(problemId, groupContestId) : URLs.getProblem(problemId))
      .then((response) => {
        setProblem(response.data);
        if (response.data.languages.length > 0) {
          // Setting the default selected language for the problem be the first language
          setCode(response.data.languages[0].skeleton);
          setSelectedLanguage({
            languageId: response.data.languages[0].languageId,
            skeleton: response.data.languages[0].skeleton,
          });
        }
      })
      .catch((err) => {
        if (err) {
          if (err) {
            if (err.response && err.response.data && err.response.data.error) {
              dispatchError({errorMessage: err.response.data.error, redirectURL: '/problems'});
            } else {
              dispatchError({errorMessage: 'There was an error. Please try again later', redirectURL: '/problems'});
            }
          }
        }
      });
  }, [problemId, contestId, update]);

  return (
    <div className="min-h-[80vh] w-full">
      {!problem ? (
        <Skeleton animation="pulse" variant="rectangular" className="h-[80vh] w-full" />
      ) : (
        <div className="grid grid-cols-12 py-2 px-3 w-full">
          <div className="col-span-4 max-h-[80vh] overflow-y-scroll">
            <div className="flex justify-between px-2">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIosNew sx={{fontSize: 30}} className="text-white" />
              </IconButton>
              {isPrivileged && (
                <div className="flex flex-row items-center justify-start">
                  <ModalWithButton
                    button={{
                      element: (
                        <IconButton>
                          <AdminPanelSettings sx={{fontSize: 24}} className="text-white" />
                        </IconButton>
                      ),
                    }}
                    children={[
                      <AuthorsManagement
                        loadAuthors={loadProblemAuthors}
                        assignAuthor={assignAuthor}
                        unassignAuthor={unassignAuthor}
                      />,
                    ]}
                    className="allowScrollbar"
                    closeModal={toggleAdminPanel}
                    height={window.innerHeight * 0.7}
                    open={adminPanelOpen}
                    openModal={toggleAdminPanel}
                    overflow="scroll"
                    width={window.innerWidth * 0.6}
                  />

                  <div className="mt-2">
                    <IconButton color="inherit" onClick={() => setEditMode(true)}>
                      <Edit sx={{fontSize: 24}} />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
            <div className="box-content justify-between h-fit box break-words">
              <Switch
                chosen={switchTo}
                direction="horizontal"
                justifyOptions="space-evenly"
                options={[
                  {
                    name: 'Details',
                    component: (
                      <Description
                        categories={problem.categories ?? []}
                        description={problem.description}
                        title={problem.title}
                        visibleTests={problem.visibleTests}
                      />
                    ),
                  },
                  {
                    name: 'My submissions',
                    component: (
                      <Submissions
                        contestId={contestId}
                        problemId={problem.id}
                        setCode={(submissionCode, newLanguageId) => {
                          setSubmissionToRestore({code: submissionCode, language_id: newLanguageId});
                        }}
                        pending={pending}
                        latestSubmission={latestSubmission}
                      />
                    ),
                  },
                ]}
                variant="outlined"
                onChange={(chosen) => setSwitchTo(chosen)}
              />
            </div>
          </div>
          <div className="col-span-8 flex flex-col gap-2">
            <div className="flex justify-end">
              <Select
                placeholder="Select a language"
                isSearchable={false}
                options={problem.languages.map((language) => ({
                  value: language.languageId,
                  label: languageOptions.find((lang) => lang.languageId == language.languageId)?.label,
                }))}
                value={{
                  value: selectedLanguage?.languageId,
                  label: languageOptions.find((lang) => lang.languageId == selectedLanguage?.languageId)?.label,
                }}
                onChange={(event) => {
                  const selected = event as {value: number; label: string};
                  const language = problem.languages.filter((lang) => lang.languageId === Number(selected.value))[0];
                  if (
                    code ==
                    problem?.languages[
                      problem?.languages.findIndex((l) => l.languageId == selectedLanguage?.languageId)
                    ].skeleton
                  ) {
                    setSelectedLanguage({languageId: language.languageId, skeleton: language.skeleton});
                    setCode(language.skeleton);
                  } else {
                    setLanguageToSet({languageId: language.languageId, skeleton: language.skeleton});
                    // setCode(language.skeleton);
                  }
                }}
                isDisabled={problem.languages.length <= 1}
                styles={dropdownStyles}
                className="max-w-[324px]"
              />
            </div>
            <div className="flex flex-col items-end">
              <CodeEditor
                className="min-h-[72vh]"
                onChange={(value) => {
                  value !== null && value !== undefined && setCode(value);
                }}
                code={code ?? ''}
                enableMinimap
                language={languageOptions.find((lang) => lang.languageId == selectedLanguage?.languageId)?.value}
              />
              <div className="flex gap-3 mt-2 items-center">
                <div className="text-gray-200 font-semibold">{`${
                  submissionTimeout ? `${formatMMSS(submissionTimeout)}` : ''
                }`}</div>
                <button
                  className="px-2 py-3 w-80 text-gray-200 font-semibold bg-primary-main rounded-lg disabled:bg-gray-600 disabled:bg-opacity-40 disabled:border-gray-400 disabled:border-opacity-10 disabled:cursor-not-allowed disabled:text-opacity-50 border border-green-800 hover:bg-green-800 transition-all disabled:pointer-events-none"
                  disabled={submissionTimeout != null}
                  onClick={onSubmit}
                >
                  Submit code
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {languageToSet && (
        <ConfirmationModal
          message={'Are you sure you want to change the language? All unsaved changes will be lost.'}
          onAccept={() => {
            setSelectedLanguage(languageToSet);
            setCode(languageToSet.skeleton);
            setLanguageToSet(null);
          }}
          acceptButton={
            <button className="px-4 py-2 bg-blue-900 rounded-md text-lg hover:bg-blue-800 transition-all">
              CONFIRM
            </button>
          }
          onClose={() => setLanguageToSet(null)}
        />
      )}
      {submissionToRestore && (
        <ConfirmationModal
          message={'Are you sure you want to restore your code? All unsaved changes will be lost.'}
          onAccept={() => {
            if (problem && submissionToRestore.language_id) {
              const language = problem.languages.filter(
                (lang) => lang.languageId == submissionToRestore.language_id
              )[0];
              if (selectedLanguage?.languageId !== language?.languageId) {
                setSelectedLanguage({languageId: language.languageId, skeleton: language.skeleton});
              }
              setCode(submissionToRestore.code);
            }
            setSubmissionToRestore(null);
          }}
          acceptButton={
            <button className="px-4 py-2 bg-blue-900 rounded-md text-lg hover:bg-blue-800 transition-all">
              CONFIRM
            </button>
          }
          onClose={() => setSubmissionToRestore(null)}
        />
      )}
      {editMode && problem && (
        <UpdateProblemModalWrapper
          problemId={problem.id}
          onSuccess={() => window.location.reload()}
          onClose={() => setEditMode(false)}
        />
      )}
    </div>
  );
};
