import MDEditor from '@uiw/react-md-editor';
import {Typography} from '@mui/material';

import {Test} from '../../types/entities/Test';
import {CodeEditor} from '../code-editor/CodeEditor';
import {Category} from '../../types/entities/Category';
import {stringsToColoredChips} from '../problems-table/Chips';

type ProblemDescriptionProps = {
  title: string;
  description: string;
  categories: Category[];
  visibleTests: Array<Test & {index: number}>;
};

export const Description = ({title, categories, description, visibleTests}: ProblemDescriptionProps) => {
  const calculateHeight = (input: string): number => {
    const lineHeight = 18;
    return Math.min(lineHeight * input.split(/\r\n|\r|\n/).length + lineHeight * 2, 200);
  };

  return (
    <div className="flex flex-col justify-evenly h-full w-full pt-0 pr-8 pb-8 pl-6 gap-2">
      <div className="flex flex-row gap-2 w-full flex-wrap">
        {categories && categories.length > 0
          ? stringsToColoredChips([...categories.map((category: Category) => category.title)])
          : stringsToColoredChips(['Uncategorized'])}
      </div>
      <div className="max-w-full">
        <Typography className="mb-10" variant="h4">
          {title}
        </Typography>
      </div>

      <div className="max-w-full">
        <MDEditor.Markdown
          source={description}
          style={{backgroundColor: 'transparent', listStyle: 'disc !important'}}
        />
      </div>
      <div>
        <Typography variant="h5" sx={{fontWeight: 600}}>
          Test cases
        </Typography>
      </div>
      <div className="flex flex-col">
        {visibleTests.map((test, index: number) => (
          <div className="flex flex-col" key={(test.index ?? index) + 1}>
            <Typography className="mt-2" variant="h6" sx={{fontWeight: 600}}>
              Test {(test.index ?? index) + 1}
            </Typography>

            <CodeEditor
              className="mb-2"
              height={calculateHeight(test.input)}
              code={test.input}
              readOnly
              title={`Input`}
            />
            <CodeEditor
              className="mb-2"
              height={calculateHeight(test.output)}
              code={test.output}
              readOnly
              title={`Expected Output`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
