import {ReactNode} from 'react';

import {Grid as MuiGrid, TextareaAutosize, Typography} from '@mui/material';

import {Results} from './results/Results';
import {Submission} from '../../types';

type PropsOnlyForModals = {
  children: Array<ReactNode>;
  height: number;
  width: number;
};

export interface OptionProps {
  index: number;
  submission: Submission;
  setCode: (newCode: string, newLanguageId: number) => void;
  userDetails?: {
    email: string;
  };
}

export class Option {
  id: string | null;
  label: string;
  onClick?: () => void;
  modal?: PropsOnlyForModals;

  constructor(id: string | null, label: string, onClick?: () => void, modal?: PropsOnlyForModals) {
    this.id = id;
    this.label = label;
    this.onClick = onClick;
    this.modal = modal;
  }

  static fromSubmission({submission, setCode}: OptionProps) {
    if (submission === Submission.PENDING) {
      return [];
    }
    const options = [new Option(null, 'Restore code', () => setCode(submission.code, submission.language_id))];
    if (!submission.error) {
      // TODO: add field to problem to check whether the refs can be displayed or not.
      options.push(
        new Option('results', 'Show results', undefined, {
          children: [
            <Results
              key={`option-show-results`}
              editor={{
                height: window.innerHeight * 0.75,
              }}
              results={submission.results}
            />,
          ],
          height: window.innerHeight * 0.9,
          width: window.innerWidth * 0.9,
        })
      );
    }

    if (submission.comments) {
      options.push(
        new Option(null, 'Show comment', undefined, {
          children: [
            <MuiGrid container spacing={10} justifyContent="center" alignItems="center" sx={{height: '100%'}}>
              <MuiGrid item xs="auto" zeroMinWidth>
                <Typography>Submission grade:</Typography>
                <TextareaAutosize
                  id="comment"
                  value={submission.grade}
                  minRows={2}
                  style={{width: '50%', fontSize: '20px'}}
                />
              </MuiGrid>
              <MuiGrid item xs="auto" zeroMinWidth>
                <Typography>Submission comments:</Typography>
                <TextareaAutosize
                  id="comment"
                  value={submission.comments}
                  minRows={20}
                  style={{width: '150%', fontSize: '20px'}}
                />
              </MuiGrid>
            </MuiGrid>,
          ],
          height: window.innerHeight * 0.7,
          width: window.innerWidth * 0.7,
        })
      );
    }

    return options;
  }
}
